/** HELPERS */
import './Helpers/Polyfill';

/** STYLES */
import '../scss/Index.scss';

/** COMMON */
import Header from './Common/Header';

class IndexController {
    constructor() {
        new Header();
    }
}

import Swiper, {Pagination} from 'swiper';

document.addEventListener("DOMContentLoaded", (evt) => {
    new IndexController();
    document.querySelectorAll('.faq').forEach(value => {
        value.addEventListener('click', () => {
            value.classList.toggle('current-faq');
        });
    });
    const swiper = document.querySelector('.swiper');
    if (swiper) {
        const x = new Swiper(swiper, {
            loop: true,
            modules: [Pagination],
            spaceBetween: 32,
            pagination: {
                el: '#pagination',
                clickable: true
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    pagination: false
                },
                1536: {
                    slidesPerView: 3,
                    pagination: false
                }
            }
        });
        document.getElementById('prev').addEventListener('click', () => x.slidePrev());
        document.getElementById('next').addEventListener('click', () => x.slideNext());
    }
    if (document.querySelector('.home')) {
        document.querySelectorAll('.scroll a').forEach(value => {
            value.addEventListener('click', ev => {
                ev.preventDefault();
                window.scrollTo({
                    top: window.scrollY + document.getElementById(value.hash.substr(1)).getBoundingClientRect().top - document.querySelector('header').offsetHeight,
                    behavior: 'smooth'
                });
            });
        });
    }
});