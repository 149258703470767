const selectors = {
    header: '.js--header',
    hamburger: '.js--menu-btn',
};

class Header {
    constructor() {
        this.header = document.querySelector(selectors.header);
        this.hamburger = document.querySelector(selectors.hamburger);

        if (!this.header) {
            return;
        }

        this.toggleMenu();
    }

    toggleMenu(){
        this.hamburger.addEventListener('click', () => {
            this.header.classList.toggle('active');
            this.hamburger.classList.toggle('active');
        });
    }
}

export default Header;
